import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useGetCompanyByIdQuery, useGetCompanyListQuery, useDeleteCompanyMutation } from '../../redux/api';
import { Layout, Button } from 'antd';
import styles from './CompanyDeletePage.module.scss';
import Title from 'antd/es/typography/Title';
import { useTypedSelector } from 'hooks/useTypedSelector';

const { Content } = Layout;

export const CompanyDeletePage = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useTypedSelector((state) => state.auth);

  const navigate = useNavigate()
  const { data: company } = useGetCompanyByIdQuery(id || '');
  const [deleteCompany, { isLoading: isUpdating }] = useDeleteCompanyMutation();
  const { refetch: refetchCompanyList } = useGetCompanyListQuery(user?.profile.id);

  const handleDeleteCompany = () => {
    if (company) {
      deleteCompany(company?.id).unwrap().then(() => {
        navigate(`/company/create`);
        refetchCompanyList();
      });
    }
  };

  return (
    <Layout>
      <Content className='page-layout'>
        <h1 className='main-title'>Удаление компании</h1>
        <Layout>
          <Content>
            <div className={styles.companyDescr}>
              <Title level={4} >Вы подтверждаете удаление компании "{company?.name}"?</Title>
              <div className={styles.buttons}>
                <Button
                  type="primary"
                  danger
                  loading={isUpdating}
                  onClick={handleDeleteCompany}
                >
                  Удалить
                </Button>
                <Button
                  type="default"
                  style={{ color: '#faad14', borderColor: '#faad14' }}
                  onClick={() => {
                    navigate(`/company/${company?.id}`)
                  }}
                  loading={isUpdating}>
                  Отмена
                </Button>
              </div>
            </div>
          </Content>
        </Layout>
      </Content>
    </Layout>
  )
}
